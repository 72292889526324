export const LOGIN = process.env.REACT_APP_BASE_URL + "admins/login";
export const ADMIN_INVITE = "admins/invite-admin";
export const FORGOT_PASSWORD =
  process.env.REACT_APP_BASE_URL + "admins/forgot-password";
export const VERIFY_PASSWORD_RESET =
  process.env.REACT_APP_BASE_URL + "admins/verify-reset-password";
export const GET_OVERVIEW_DATA_COUNT = "admins/overview/data-count";
export const GET_ACCOUNT_DATA_COUNT = "admins/account/data-count";
export const GET_FLOW = "admins/overview/graph-data?duration";
export const GET_ALL_ACCOUNT = "admins/account";
export const GET_ALL_BUSINESS = "admins/business/business-registrations";
export const GET_ALL_BUSINESS_REQ_COUNT =
  "admins/business/business-registrations/data-count";
export const GET_TRANS_SUMMARY = "admins/account";
export const GET_ACCOUNTS_FOR_BUSINESS = "admins/business";
export const ALL_USERS = "admins/management/user";
export const ALL_ROLES = "roles/find-all";
export const SEND_OTP = "admins/send-action-otp";
export const RESEND_OTP = "admins/resend-action-otp";
export const VERIFY_OTP = "admins/verify-action-otp";
export const INV_ADMIN = "admins/teams/invite-admin";
export const VERIFY_ADMIN = "admins/verify-admin-invite";
export const GET_ADMINS = "admins/teams/find-all-team-members";
export const DEL_ADMIN = (id = "") => `admins/teams/delete-team-member/${id}`;
export const DEL_ROLES = (id = "") => `roles/remove/${id}`;
export const PRE_PERMISSIONS = "permissions/predefined-permissions";
export const ALL_USERS_COUNT = "admins/management/user/data-count";
export const CREATE_ROLE = "roles/create";
export const EDIT_ROLE = (id = "") => `roles/update/${id}`;
export const EDIT_USER_DIR = (id = "") =>
  `/admins/management/user/edit-other-director/${id}`;
export const APPROVE_BUS = (id = "") =>
  `admins/business/business-registrations/${id}/approve`;
export const ONE_ROLE = (id = "") => `roles/${id}`;
export const ONE_ADMIN = (id = "") => `admins/teams/get-team-member/${id}`;
export const GET_ONE_USER = "admins/management/user";
export const GET_ADMIN = "admins/profile";
export const ADMIN_TRANSACTIONS = "admins/transactions";
export const INVOICES = "admins/management/invoice/get-all-invoices";
export const INVOICE_COUNT = "admins/management/invoice/get-invoice-count";
export const ADMIN_TRANSACTIONS_SUMMARY = "admins/transactions/summary";
export const BUSINESS_REG_COUNT =
  "admins/management/business-registrations/data-count";
export const BUSINESS_REG = "admins/management/business-registrations";
export const UPDATE_PASSWORD = "admins/update-admin-passowrd";
export const EDIT_ONE_USER = (userRef = "") =>
  `admins/management/user/${userRef}/edit`;
export const ACCOUNT_PROFILE = "admins/user";
export const ACTIVITY_LOG = "admins/activity-logs";
export const ALL_BANKS = "app-setting/get-account-options";
export const USER_ACCOUNTS = "admins/management/user";
export const UPDATE_SETTINGS = "app-setting/update";
export const UPDATE_CHARGES = "app-setting/update-charges";

export const FETCH_SETTINGS = "app-setting/find-all";
export const SUSPEND_ACCOUNT = (id = "") =>
  `admins/account/${id}/suspension-toggle`;
export const TOGGLE_ACCOUNT = (id = "") =>
  `app-setting/toggle-account-option/${id}`;
export const BLOCK_USER = (id = "") =>
  `admins/management/user/${id}/blocking-toggle`;
export const INVOICE_SUB = "admins/management/invoice/invoice-subscription";
export const INVOICE_SUBS = "admins/management/invoice/subscription";
export const INVOICES_SUB = (id = "") =>
  `admins/management/invoice/subscription/${id}`;
export const AUDIT = "admins/management/invoice/invoice-audit-trail";
export const ONE_INVOICE = "admins/management/invoice/get-invoice";
export const SUSPEND_USER = (id = "") =>
  `admins/management/user/${id}/suspension-toggle`;
export const COUNTRIES_STATES =
  "https://countriesnow.space/api/v0.1/countries/states";

export const GET_ALL_CARDS = "admins/cards/get-all-cards";
export const GET_CARDS_COUNT = "admins/cards/data-count";
export const GET_VIRTUAL_CARDS = "admins/cards/virtual";
export const GET_PHYSICAL_CARDS = "admins/cards/physical";
export const GET_CARDS_DETAILS = "admins/cards/card-details";
export const GET_CARD_TRANSACTIONS = "admins/cards/card-transactions";
export const SUSPEND_CARD = "admins/cards/suspend-card";
export const UNSUSPEND_CARD = "admins/cards/unsuspend-card";

export const GET_USD_RATE = "admins/cards/get-usd-rate";
export const GET_GLOBAL_RATE = "admins/cards/get-global-account-rates";
export const UPDATE_KYC = (id = "") => `admins/account/tiers/${id}`;
export const GET_ALL_ACCOUNT_TIERS = "admins/account/tiers";
export const UPDATE_USER_KYC_DOCUMENTS =
  "admins/management/user/identity/update";
export const GET_USER_KYC_DOCUMENTS = `admins/management/user`;
